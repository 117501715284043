<template>
  <b-modal
    id="AddModalUser"
    centered
    size="lg"
    no-close-on-backdrop
    :title="$t('users.add_user')"
    @show="openModal"
  >
    <div slot="modal-footer">
      <b-button
        class="mx-1"
        variant="danger"
        @click="$bvModal.hide('AddModalUser')"
      >
        {{ $t('general.cancel') }}
      </b-button>
      <b-button
        variant="success"
        @click="createUsers"
      >
        {{ $t('general.save') }}
      </b-button>
    </div>
    <validation-observer ref="usersValidation">
      <b-row>
        <b-col cols="6">

          <b-form-group
            :label="$t('users.last_name')"
            label-for="last_name"
          >
            <validation-provider
              #default="{ errors }"
              rules=""
              :name="$t('users.last_name')"
            >
              <b-form-input
                id="last_name"
                v-model="user.contact.last_name"
                lazy-formatter
                :placeholder="$t('users.last_name')"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            :label="$t('users.first_name')"
            label-for="first_name"
          >
            <validation-provider
              #default="{ errors }"
              rules=""
              :name="$t('users.first_name')"
            >
              <b-form-input
                id="first_name"
                v-model="user.contact.first_name"
                lazy-formatter
                :placeholder="$t('users.first_name')"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            :label="$t('users.middle_name')"
            label-for="middl_name"
          >
            <validation-provider
              #default="{ errors }"
              rules=""
              :name="$t('users.middle_name')"
            >
              <b-form-input
                id="middl_name"
                v-model="user.contact.middle_name"
                :placeholder="$t('users.middle_name')"
                :state="errors.length > 0 ? false : null"
                name="reset-password-login"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- <div class="d-flex align-items-center justify-content-between">
            <b-form-group :label="$t('Статус')">
              <b-form-checkbox
                v-model="user.is_active"
                name="check-button"
                switch
                inline
              />
            </b-form-group>
            <b-form-checkbox
              v-if="modalType"
              v-model="selected"
              :value="true"
            >
              {{ $t('Пароль') }}
            </b-form-checkbox>
          </div> -->
        </b-col>

        <b-col cols="6">

          <b-form-group
            :label="$t('users.login')"
            label-for="loginInput"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('users.login')"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  {{ branch_code }}
                </b-input-group-prepend>
                <b-form-input
                  id="loginInput"
                  v-model="user.username"
                  :placeholder="$t('users.login')"
                  :state="validateState(errors)"
                  name="reset-password-login"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Пароль -->
          <span>
            <b-form-group
              :label="$t('users.password')"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                vid="Password"
                rules="required"
                :name="$t('users.password')"
              >
                <b-form-input
                  id="password"
                  v-model="user.password"
                  type="password"
                  :placeholder="$t('users.enter_password')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Повторить пароль -->
            <b-form-group
              :label="$t('users.confirm_password')"
              label-for="rePassword"
            >
              <validation-provider
                #default="{ errors }"
                rules="required|confirmed:Password"
                :name="$t('users.confirm_password')"
              >
                <b-form-input
                  id="rePassword"
                  v-model="rePassword"
                  type="password"
                  :placeholder="$t('users.confirm_password')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </span>
        </b-col>
        <b-col>
          <b-form-group
            :label="$t('users.role')"
            label-for="role"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('users.role')"
            >
              <v-select
                id="role"
                v-model="user.roles"
                multiple
                label="contact.title"
                :get-option-label="getLabel"
                :options="GET_USER_ROLES_LIST.results"
                :placeholder="$t('users.role')"
                @input="getPermissionRole"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col v-if="$can('manage', 'super_admin') || $can('manage', 'head_manager')">
          <b-form-group
            v-if="hasBranch"
            :label="$t('others.branch')"
            label-for="role"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('others.branch')"
            >
              <v-select
                id="role"
                v-model="user.contact.branch"
                label="title"
                :options="GET_APPROVED_BRANCH.results"
                :placeholder="$t('others.branch')"
                :reduce="user => user.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- <b-col cols="6">
          <b-form-group
            :label="$t('Номер телефона')"
            label-for="phoneInput"
          >

            <validation-provider
              #default="{ errors }"
              :rules="{ required: true, min: 12 }"
              name="Номер телефона"
            >
              <cleave
                id="phoneInput"
                v-model="user.contact.phone"
                type="tel"
                :number="true"
                placeholder="998 9x xxx-xx-xx"
                class="form-control"
                :options="phoneFieldOptions"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          class="mt-2"
        >
          <b-form-group
            label-for="phoneNumber"
          >
            <validation-provider
              #default="{ errors }"
              rules=""
              name="Телефон номер"
            >

              <b-form-checkbox
                :value="true"
              >
                {{ $t('Номер от родителя') }}
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col> -->
        <validation-observer />
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BRow,
  BCol,
  BButton,
  BInputGroupPrepend,
  BInputGroup,
  // BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
// import Cleave from 'vue-cleave-component'
import toast from '@/mixins/toast'
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'AddModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormInput,
    BFormGroup,
    vSelect,
    // Cleave,
    BRow,
    BCol,
    BButton,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [toast],
  props: {
    defaultRole: {
      type: String,
      required: false,
      default: '',
    },
    defaultParams: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      rePassword: '',
      required,
      option: [],
      password: '',
      selected: true,
      params: this.defaultParams,
      hasBranch: true,
      user: {
        contact: {
          first_name: '',
          last_name: '',
          middle_name: '',
          branch: '',
        },
        password: '',
        roles: [],
      },
      phoneFieldOptions: {
        numericOnly: true,
        blocks: [3, 2, 3, 2, 2],
        delimiters: [' ', ' ', '-'],
      },
      branch_code: useJwt.getUserData().contact.branch?.branch_code || '',
    }
  },
  computed: {
    ...mapGetters('users', ['GET_USER_ROLES_LIST']),
    ...mapGetters('branches', ['GET_APPROVED_BRANCH']),
  },
  // watch: {
  //   'user.username': {
  //     handler(value) {
  //     // this.$nextTick(() => {
  //     //   // data.push('Поле Логин обязательно для заполнения')
  //     //   this.$refs.usersValidation.errors[this.$t('users.login')].push('Поле Логин обязательно для заполнения')
  //     // })
  //       if (!/[a-zA-Z]+/gm.test(value)) {
  //         setTimeout(() => {
  //           this.$refs.usersValidation.errors[this.$t('users.login')].push('Поле 324')
  //         }, 50)
  //       }
  //     },
  //   },
  // },
  mounted() {
    this.FETCH_USER_ROLES_LIST()
    this.FETCH_APPROVED_BRANCH_LIST()
  },
  methods: {
    ...mapActions('users', [
      'FETCH_USER_ROLES_LIST',
      'CREATE_USER',
      'FETCH_USERS_LIST',
    ]),
    ...mapActions('branches', ['FETCH_APPROVED_BRANCH_LIST']),
    getLabel(element) {
      if (element.title) return element.title[this.$i18n.locale]
      return element
    },
    getPermissionRole() {
      this.hasBranch = !this.user.roles.some(e => ['head_manager', 'super_admin', 'content_manager'].includes(e.unique_name))
    },
    validateState(errors) {
      if (!/[a-zA-Z]+/gm.test(this.user.username)) {
        setTimeout(() => {
          if (!this.$refs.usersValidation.errors[this.$t('users.login')].includes(`Поле ${this.$t('users.login')} должен содержать хотя бы одну букву`)) {
            this.$refs.usersValidation.errors[this.$t('users.login')].push(`Поле ${this.$t('users.login')} должен содержать хотя бы одну букву`)
          }
        }, 100)
        return false
      }
      return errors.length > 0 ? false : null
    },

    openModal() {
      this.rePassword = ''
      this.user = {
        contact: {
          first_name: '',
          last_name: '',
          middle_name: '',
          branch: null,
        },
        password: '',
        // roles: this.GET_USER_ROLES_LIST.results.filter(el => (el.unique_name === 'student' ? el.id : null)),
      }
      if (this.defaultRole === 'student') {
        this.user.roles = [
          {
            id: 1,
            title: {
              en: 'Student',
              ru: 'Студент',
              uz: 'Student',
            },
            unique_name: 'student',
          },
        ]
      } else if (this.defaultRole === 'teacher') {
        this.user.roles = [
          {
            id: 3,
            title: {
              en: 'Teacher',
              ru: 'Учитель',
              uz: "O'qituvchi",
            },
            unique_name: 'teacher',
          },
        ]
      } else this.user.roles = []
    },

    createUsers() {
      this.$refs.usersValidation.validate().then(async success => {
        if (success) {
          if (!this.$can('manage', 'super_admin') && !this.$can('manage', 'head_manager')) {
            this.$delete(this.user.contact, 'branch')
          }
          if (!this.hasBranch) {
            this.$set(this.user.contact, 'branch', null)
          }
          const data = JSON.parse(JSON.stringify(this.user))
          data.roles = this.user.roles.map(e => e.id)
          data.username = this.branch_code + data.username
          await this.CREATE_USER(data)
            .then(res => {
              this.$_successToast(this.$t('general.added'))
              this.$bvModal.hide('AddModalUser')
              this.$router.push(`/users/${res.data.id}/info`)
              // this.params.page = 1
              // this.FETCH_USERS_LIST(this.params)
            })
            .catch(e => {
              if (e.response.data.username) {
                this.$_errorToast(e.response.data.username[0])
              } else if (e.response.data.error) {
                this.$_errorToast(e.response.data.error)
              } else {
                this.$_errorToast(this.$t('general.error'))
              }
            })
        }
      })
    },
  },
}
</script>
