<template>
  <div>
    <full-table
      :other-filter-props="{
        add:
          $can('manage', 'id_manager') ||
          $can('manage', 'admin') ||
          $can('manage', 'super_admin') ||
          $can('manage', 'head_manager'),
        search: true,
        gender: true,
        level: true,
        subject: true,
        branchs:
          $can('manage', 'super_admin') || $can('manage', 'head_manager'),
      }"
      :items="GET_USERS_LIST.results"
      :fields="fields"
      :rows="GET_USERS_LIST.count"
      :edit-btn="false"
      :delete-btn="
        $can('manage', 'id_manager') ||
          $can('manage', 'admin') ||
          $can('manage', 'super_admin') ||
          $can('manage', 'head_manager')
      "
      :first-params="params"
      :empty-text="$t('general.no_data_to_view')"
      @refresh="FETCH_USERS_LIST"
      @row-clicked="(data) => $router.push(`/users/${data.id}/info`)"
      @detail-button-clicked="(id) => $router.push(`/users/${id}/info`)"
      @delete-button-clicked="modalDeleteTeacher"
      @add-button-clicked="addUser"
    />
    <add-modal
      :default-role="defRole"
      :default-params="params"
    />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import toast from '@/mixins/toast'
import areYouSure from '@/mixins/areYouSure'
import FullTable from '@/components/FullTable.vue'
import AddModal from '../components/modals/AddUserModal.vue'

export default {
  components: {
    FullTable,
    AddModal,
  },
  directives: {
    Ripple,
  },
  mixins: [toast, areYouSure],
  data() {
    return {
      defRole: 'teacher',
      params: {
        page_size: 10,
        page: 1,
        ordering: null,
        search: null,
        role__unique_name: 'teacher',
      },
      fields: [
        {
          key: 'contact.id',
          label: 'ID',
          sortable: true,
        },
        {
          label: 'users.full_name',
          key: 'contact.full_name',
          sortable: true,
        },
        {
          key: 'phones',
          label: 'users.phone_number',
        },
        {
          label: 'users.email',
          key: 'contact.email',
        },
        {
          label: 'users.branchs',
          key: 'contact.branch.title',
        },
        // {
        //   label: 'Профиль (предмет)',
        //   key: 'sciences',
        // },
        {
          label: 'users.level',
          key: 'contact.level',
        },
        // {
        //   label: 'users.count_students',
        //   key: 'contact.number_students',
        // },
        // {
        //   label: 'users.status',
        //   key: 'contact.is_active',
        // },
        {
          label: 'general.actions',
          key: 'actions',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('users', ['GET_USERS_LIST']),
  },
  beforeDestroy() {
    this.SET_USERS_LIST([])
  },
  methods: {
    ...mapMutations('users', ['SET_USERS_LIST']),
    ...mapActions('users', ['FETCH_USERS_LIST', 'DELETE_USER']),
    addUser() {
      this.$bvModal.show('AddModalUser')
    },
    modalDeleteTeacher(id, params) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.DELETE_USER(id)
            .then(() => {
              this.$_successToast(this.$t('general.removed'))
              this.FETCH_USERS_LIST(params)
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        }
      })
    },
  },
}
</script>

<style></style>
