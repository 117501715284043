var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"AddModalUser","centered":"","size":"lg","no-close-on-backdrop":"","title":_vm.$t('users.add_user')},on:{"show":_vm.openModal}},[_c('div',{attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('b-button',{staticClass:"mx-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.$bvModal.hide('AddModalUser')}}},[_vm._v(" "+_vm._s(_vm.$t('general.cancel'))+" ")]),_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.createUsers}},[_vm._v(" "+_vm._s(_vm.$t('general.save'))+" ")])],1),_c('validation-observer',{ref:"usersValidation"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.last_name'),"label-for":"last_name"}},[_c('validation-provider',{attrs:{"rules":"","name":_vm.$t('users.last_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_name","lazy-formatter":"","placeholder":_vm.$t('users.last_name'),"state":errors.length > 0 ? false : null},model:{value:(_vm.user.contact.last_name),callback:function ($$v) {_vm.$set(_vm.user.contact, "last_name", $$v)},expression:"user.contact.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('users.first_name'),"label-for":"first_name"}},[_c('validation-provider',{attrs:{"rules":"","name":_vm.$t('users.first_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","lazy-formatter":"","placeholder":_vm.$t('users.first_name'),"state":errors.length > 0 ? false : null},model:{value:(_vm.user.contact.first_name),callback:function ($$v) {_vm.$set(_vm.user.contact, "first_name", $$v)},expression:"user.contact.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('users.middle_name'),"label-for":"middl_name"}},[_c('validation-provider',{attrs:{"rules":"","name":_vm.$t('users.middle_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"middl_name","placeholder":_vm.$t('users.middle_name'),"state":errors.length > 0 ? false : null,"name":"reset-password-login"},model:{value:(_vm.user.contact.middle_name),callback:function ($$v) {_vm.$set(_vm.user.contact, "middle_name", $$v)},expression:"user.contact.middle_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.login'),"label-for":"loginInput"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('users.login')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" "+_vm._s(_vm.branch_code)+" ")]),_c('b-form-input',{attrs:{"id":"loginInput","placeholder":_vm.$t('users.login'),"state":_vm.validateState(errors),"name":"reset-password-login"},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('span',[_c('b-form-group',{attrs:{"label":_vm.$t('users.password'),"label-for":"password"}},[_c('validation-provider',{attrs:{"vid":"Password","rules":"required","name":_vm.$t('users.password')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","type":"password","placeholder":_vm.$t('users.enter_password')},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('users.confirm_password'),"label-for":"rePassword"}},[_c('validation-provider',{attrs:{"rules":"required|confirmed:Password","name":_vm.$t('users.confirm_password')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rePassword","type":"password","placeholder":_vm.$t('users.confirm_password')},model:{value:(_vm.rePassword),callback:function ($$v) {_vm.rePassword=$$v},expression:"rePassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('users.role'),"label-for":"role"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('users.role')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"role","multiple":"","label":"contact.title","get-option-label":_vm.getLabel,"options":_vm.GET_USER_ROLES_LIST.results,"placeholder":_vm.$t('users.role')},on:{"input":_vm.getPermissionRole},model:{value:(_vm.user.roles),callback:function ($$v) {_vm.$set(_vm.user, "roles", $$v)},expression:"user.roles"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.$can('manage', 'super_admin') || _vm.$can('manage', 'head_manager'))?_c('b-col',[(_vm.hasBranch)?_c('b-form-group',{attrs:{"label":_vm.$t('others.branch'),"label-for":"role"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('others.branch')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"role","label":"title","options":_vm.GET_APPROVED_BRANCH.results,"placeholder":_vm.$t('others.branch'),"reduce":function (user) { return user.id; }},model:{value:(_vm.user.contact.branch),callback:function ($$v) {_vm.$set(_vm.user.contact, "branch", $$v)},expression:"user.contact.branch"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,822809052)})],1):_vm._e()],1):_vm._e(),_c('validation-observer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }